const initialStates={

    jobsData:[],
    completedJobs:[],
    // waiting aka new jobs
    newJobs:[],
    assignedJobs:[],
    cancelledJobs:[],
    agentsData:[],
    onlineAgentsData:[],
    freeAgentsData:[],
    onJobAgentsData:[],
    totalEarningsData:''
}

const dashboardReducer=(state=initialStates,action)=>{

    switch(action.type){

        case "DASHBOARD_JOBSDATA":
            return{
                ...state,
                jobsData:action.payload
            }
        case "DASHBOARD_COMPLETED_JOBS_DATA":
            return{
                ...state,
                completedJobs:action.payload
            }
        case "DASHBOARD_NEW_JOBS_DATA":
            return{
                ...state,
                newJobs:action.payload
            }
        case "DASHBOARD_ASSIGNED_JOBS_DATA":
            return{
                ...state,
                assignedJobs:action.payload
            }
        case "DASHBOARD_CANCELLED_JOBS_DATA":
            return{
                ...state,
                cancelledJobs:action.payload
            }
        case "DASHBOARD_AGENTSDATA":
            return{
                ...state,
                agentsData:action.payload
            }
        case "DASHBOARD_FREEAGENTS_DATA":
            return{
                ...state,
                freeAgentsData:action.payload
            }
        case "DASHBOARD_TOTALEARNINGS_DATA":
            return{
                ...state,
                totalEarningsData:action.payload
            }
        case "DASHBOARD_ONJOBAGENTS_DATA":
            return{
                ...state,
                onJobAgentsData:action.payload
            }
        case "DASHBOARD_ONLINEAGENTS_DATA":
            return{
                ...state,
                onlineAgentsData:action.payload
            }
        // case "ACCESS_RIGHTS_ACTIVE_TAB":
        //     return{
        //         ...state,
        //         activeTab:action.payload
        //     }
        default:
            return state
    }
}

export default dashboardReducer