import ManageServices from "../../../utility/api/services/ManageServices"

// city get api
export const getCityData = async () => {
    try {
        const response = await ManageServices.getManageCityData()
        const data = await response.data
        
        return data
    } catch (err) {
        console.log('ERROR in manageData getCityData', err)
    }
}

export const getVendorData = async () => {
    try {
        const response = await ManageServices.getManageVendorData()
        const data = await response.data
        
        return data
    } catch (err) {
        console.log('ERROR in manageData get Vendors', err)
    }
}

export const getTimeSlotData = async () => {
    try {
        const response = await ManageServices.getManageTimeSlotsData()
        const data = await response.data
        
        return data
    } catch (err) {
        console.log('ERROR in manageData get Time Slot', err)
    }
}

export const getProvinceData = async () => {
    try {
        const response = await ManageServices.getManageProvinceData()
        const data = await response.data
        return data
    } catch (err) {
        console.log('ERROR in manageData getProvinceData', err)
    }
}

// customer get api
export const getCustomerData = async () => {
    try {
        const response = await ManageServices.getManageCustomerData()
        const data = await response.data
        console.log('DATA in ManageData getCustomerData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getCustomerData', err)
    }
}

export const getVehicleData = async () => {
    try {
        const response = await ManageServices.getManageVehicleData()
        const data = await response.data
        console.log('DATA in ManageData getVehicleData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getVehicleData', err)
    }
}

export const getCategoryData = async () => {
    try {
        const response = await ManageServices.getManageCategoryData()
        const data = await response.data
        console.log('DATA in ManageData getCategoryData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getCategoryData', err)
    }
}

export const getSubCategoryData = async () => {
    try {
        const response = await ManageServices.getManageSubCategoryData()
        const data = await response.data
        console.log('DATA in ManageData getSubCategoryData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getSubCategoryData', err)
    }
}

export const getBookingData = async () => {
    try {
        const response = await ManageServices.getManageBookingData()
        const data = await response.data
        console.log('DATA in ManageData getBookingData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getBookingData', err)
    }
}

export const getProductData = async () => {
    try {
        const response = await ManageServices.getManageProductData()
        const data = await response.data
        console.log('DATA in ManageData getProductData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getProductData', err)
    }
}

export const getAdminData = async () => {
    try {
        const response = await ManageServices.getManageAdminData()
        const data = await response.data
        console.log('DATA in ManageData getAdminData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getAdminData', err)
    }
}
export const getAgentData = async () => {
    try {
        const response = await ManageServices.getManageAgentData()
        const data = await response.data
        console.log('DATA in ManageData getAgentData', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData getAgentData', err)
    }
}
export const getLiveView = async () => {
    try {
        const response = await ManageServices.getLiveViewData()
        const data = await response.data
        console.log('DATA in manageData => getLiveView', data)
        return data
    } catch (err) {
        console.log('ERROR in manageData => getLiveView', err)
    }
}
export const getVerifyEmail= async (payload) => {
    try {
        const response = await ManageServices.getVerifyEmail(payload)
        const data = await response.data
        console.log('DATA in Verify Email', data)
        return data
    } catch (err) {
        console.log('ERROR in verify Email', err)
    }
}

export const getVerifyEmailWithoutToken= async (payload) => {
    try {
        const response = await ManageServices.getVerifyEmailWithoutToken(payload)
        const data = await response.data
        console.log('DATA in Verify Email', data)
        return data
    } catch (err) {
        console.log('ERROR in verify Email', err)
    }
}

export const getRepeatJobsData= async (payload) => {
    try {
        const response = await ManageServices.getRepeatJobsData(payload)
        const data = await response.data
        console.log('DATA in Verify Email', data)
        return data
    } catch (err) {
        console.log('ERROR in verify Email', err)
    }
}

export const postRepeatJobsData= async (payload) => {
    try {
        const response = await ManageServices.postRepeatJobsData(payload)
        const data = await response.data
        console.log('DATA in Verify Email', data)
        return data
    } catch (err) {
        console.log('ERROR in verify Email', err)
    }
}
export const postCustomerGroupVerification= async (payload) => {
    try {
        const response = await ManageServices.postCustomerGroupVerification(payload)
        const data = await response.data
        console.log('DATA in Verify Email', data)
        return data
    } catch (err) {
        console.log('ERROR in verify Email', err)
    }
}

export const getArtistSound = async (artist_id) => {
    try {
        const response = await ManageServices.getArtistSound(artist_id)
        const data = await response.data
        
        return data
    } catch (err) {
        console.log('ERROR in manageData getCityData', err)
    }
}

export const getArtistSoundWithLocking = async (page) => {
    try {
        const response = await ManageServices.getArtistSoundWithLocking(page)
        const data = await response.data
        
        return data
    } catch (err) {
        console.log('ERROR in manageData getCityData', err)
    }
}