
const initialState={

    access:[],
    testLogin:false
}

const accessPage = (state = initialState, action) => {
    
    switch(action.type){
        
        case "CHANGE_ACCESS":
            return{
                ...state,
                access:action.payload
            }
        case "TEST_LOGIN":
            return{
                ...state,
                testLogin:action.payload
            }
        default:
            return state
    }

  }
  
  export default accessPage
  