
import { CheckCircle } from "react-feather";
import themeConfig from "../../configs/themeConfig";

export const customStyles = {
    
    table:{
        style:{
            height:'',
            
        }
    },
    headCells: {
        style: {
            // backgroundColor:"#433D85",
            background: "#433d85",
            background: "-webkit-linear-gradient(146deg, #00000 0%, rgba(0, 0, 0, 0.9) 100%)",
            background: 'linear-gradient(146deg, #000000 0%, rgba(0, 0, 0, 0.9) 100%)',
            fontWeight:"bold",
            border: "1px solid #000000",
            color:"#f5f5f5 !important",
            height:"50px",
            fontSize:"12px",
            
        }
    },
    rows:{
        style:{
            fontSize:"12px",
            maxHeight:'80px',
            minHeight:'60px',
            
        }
    },
    cells:{
        style:{
            width:'90px',
            heigth:'35px',
        }
    },
    pagination:{
        style:{
            
        }
    }
}  

export const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {})

export function descendCompare( a, b ) {
    if ( a.uid > b.uid ){
      return -1;
    }
    if ( a.uid < b.uid ){
      return 1;
    }
    return 0;
  }


export const jobStatusOptions=[
    {label:"New",value:"WAITING"},
    // {label:"In-Process",value:"COMPLETE"},
    {label:"Assigned",value:"INPROCESS"},
    {label:"Completed",value:"COMPLETED"},
    {label:"Cancelled",value:"CANCELLED"},
]

const toFormatDate=(passDate)=>{
    
    const date= new Date(passDate)

    const year= date.getFullYear()
    const month= date.getMonth()+1
    const day= date.getDate()

    const finalDate= month+'/'+day+'/'+year

    return finalDate
}
export const formatDate=(date)=>{

    if(date){

        return toFormatDate(date)

    }else{
        
        return toFormatDate(new Date())
    }
}

export const getImageUrl = (imageUrl) => {

    const url = localStorage.getItem('baseURL')
    // const newUrl = url.split('/')
    // newUrl.pop()
    // if(imageUrl=='undefined')
    // {

    // }
    return url + '/uploads/' + imageUrl
}

export const userRoles={
    'admin':'Admin',
    'artist':'Artist',
    'tiktoker':'Tiktoker',
    'brand':'Brand'

}

  export function getDaysForMonth(year, month) {
    // Note that the month argument is zero-based (i.e. January is 0, February is 1, etc.)
    return new Date(year, month + 1, 0).getDate();
  }

  export function getDatesAndDaysOfMonth(daysOfMonth,month,year) {
    const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth();
    const datesAndDays = [];
  
      for(let i=1;i<=daysOfMonth;i++){
          const dayOfMonth = i;
          
          const date = new Date(year, month, dayOfMonth);
          const dayOfWeek = date.toLocaleString('default', { weekday: 'long' });
          datesAndDays.push({date: `${date1} , ${dayOfWeek} ` });
  
      }
  
    return datesAndDays;
  }

export const onlineStatus={
    "ONLINE":'online',
    "OFFLINE":'busy',
    'AWAY':'away',
    'HIBERNATE':'offline'
}
export const customerVerification={
    "VERIFIED": <CheckCircle color="#28C76F" size="20"/>
}
export const jobStatus=['COMPLETED','ASSIGNED','INPROCESS']
export const jobStatusText = {
    'COMPLETED' : 'Completed By: ',
    'ASSIGNED' : 'Assigned To: ',
    'INPROCESS' : 'Assigned To: '
}
export const handleFilter = (value,data) => {
    
    const keys = data && data.length!==0 && Object.keys(data[0])

    let updatedData = []
    
    if (value.length && data && data.length!==0) {
        
        updatedData = data.filter((item) => {
            
            let startsWith = keys.map(key => {
                return item[key] && item[key] !== null && item[key]?.toString()
                    .toLowerCase()
                    .startsWith(value.toLowerCase())
            })

            startsWith = startsWith.includes(true)

            let includes = keys.map(key => {
                return item[key] && item[key] !== null && item[key]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            })

            includes = includes.includes(true)
                
            if (startsWith) {
                return startsWith
            } else if (!startsWith && includes) {
                return includes
            } else return null
        })
        return updatedData
    }
}

export const getFormattedDate=(a)=>{

    var date = new Date(a);
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric',second:'numeric', hour12: false};
    var dateString = date.toLocaleString('sv-SE', options);

    console.log("DATE STRING :",dateString)
    return dateString
}