// import { createSlice } from "@reduxjs/toolkit";

import { indexingOfProducts } from "./Action"

// export const booking = createSlice({
//     name:"booking",
//     initialState:{
//         data:[]
//     },
//     reducers:{
//         book:(state, action)=>{
//             console.log('SLICE BOOKING')
//             state.data= action.payload
//             console.log('sliced')
//         }
//     }
// });

// export const {book} = booking.actions;
// export default booking.reducer;
const initialState={

    bookingData:[],
    productsData:[],
    productIndexes:[],
    
    timeslotData:[],
}

const manageReducer= (state=initialState,action)=>{

    switch(action.type){

        case 'MANAGE_BOOKING_DATA':
            
            return {
                ...state,
                bookingData:action.payload
               
            }
        case 'MANAGE_PRODUCTS_DATA':
            const {obj,sortedData} = indexingOfProducts(action.payload)

            return {
                ...state,
                productsData:sortedData,
                productIndexes:obj
            }
        case 'MANAGE_TIMESLOT_DATA':
            
            return {
                ...state,
                timeslotData:action.payload
                
            }
        case 'REFRESH_MANAGE_STORE':
            console.log('  %% %% % CALLE D')
            return{
                bookingData:[],
                productsData:[],
                productIndexes:[],
                
                timeslotData:[],
            }
            
        default:
            return state
    }
}

export default manageReducer