import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import {store} from "../../../redux/store"
import { handleLogout } from '../../../redux/authentication'
import toast from 'react-hot-toast'
import { getHeaders } from '../../../auth/utils'
import themeConfig from '../../../configs/themeConfig'


var Headers=getHeaders()

const refreshHeader=()=>{

  Headers=getHeaders()

}
axios.interceptors.response.use(
  response => response,
  error => {
    if(error.toString().includes('401'))
    {
      toast.error("Un-Authorized !!")
      store.dispatch(handleLogout())
      setTimeout(()=>{
        window.location.href = '/login';
      },2000)
    
    }
  }
)
export {axios,Headers,refreshHeader};
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    // axios.interceptors.request.use(
    //   config => {
    //     // ** Get token from localStorage
    //     const accessToken = this.getToken()

    //     // ** If token is present add it to request's Authorization Header
    //     if (accessToken) {
    //       // ** eslint-disable-next-line no-param-reassign
    //       config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
    //     }
    //     return config
    //   },
    //   error => Promise.reject(error)
    // )

    // // ** Add request/response interceptor
    // axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     console.log("err:",error)
    //     if(error.toString().includes('401'))
    //     {
    //       toast.error("Un-Authorized !!")
    //       store.dispatch(handleLogout())
    //       setTimeout(()=>{
    //         window.location.href = '/login';
    //       },2000)
        
    //     }
    //   }
    // )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  login(...args) {
   
    return axios.post(localStorage.getItem('baseURL')+this.jwtConfig.loginEndpoint,...args, {
      headers: {
          'render':'backend',
      }
    })
  }

}
