import { store } from '../../../redux/store'
import ManageServices from '../../../utility/api/services/ManageServices'
import { getProducts } from '../../apps/ecommerce/Action/Action'
import { getBookingData, getProductData, getTimeSlotData } from '../ManageData/ManageData'

const bookingHandler=()=>{
    return dispatch =>{

        getBookingData()
            .then((res)=>{
                const data = res.map((e,i) => ({...e, sn:i+1}))
                
                if(data){
                    dispatch({type:'MANAGE_BOOKING_DATA',payload:data})
                }
           })
        .catch((err) => console.log('error BOOKING: ', err))
        
    }
}

const bookingHandlerForShop=()=>{
    return dispatch =>{

        const manageStore = store.getState().ManageReducer

        if(manageStore.bookingData.length)
        return 
        
        ManageServices.getBookingFormDataForShop()
            .then((res)=>{
                const data = res.data.map((e,i) => ({...e, sn:i+1}))
                
                if(data){
                    dispatch({type:'MANAGE_BOOKING_DATA',payload:data})
                }
           })
        .catch((err) => console.log('error BOOKING: ', err))
        
    }
}

const getProductsForShop=()=>{
    return async dispatch =>{
       
        const storeProducts = store.getState().ManageReducer.productsData

        if(storeProducts.length)
        return

        const response = await getProducts()
        
        if(response?.data){
                const data = response.data.map((e,i) => ({...e, sn:i+1}))
            
                if(data){
                    // store.dispatch({type:'SET_ALL_PRODUCTS',payload:data})
                    dispatch({type:'MANAGE_PRODUCTS_DATA',payload:data})
                }
        }
        //     .then((res)=>{
        //         const data = res.map((e,i) => ({...e, sn:i+1}))
               
        //         if(data){
        //             dispatch({type:'MANAGE_PRODUCTS_DATA',payload:data})
        //         }
        //    })
        // .catch((err) => console.log('error PRODUCTS: ', err))
        
    }
}

const productsHandler=()=>{
    return dispatch =>{
       
         const storeProducts = store.getState().ManageReducer.productsData

        if(storeProducts.length)
        return

        getProductData()
            .then((res)=>{
                const data = res.map((e,i) => ({...e, sn:i+1}))
               console.log(' $ DATA !  !! !: ',data)
                if(data){
                    console.log(' $ DATA : ',data)
                    dispatch({type:'MANAGE_PRODUCTS_DATA',payload:data})
                }
           })
        .catch((err) => console.log('error PRODUCTS: ', err))
        
    }
}

const timeslotHandler=()=>{
    return dispatch =>{
        console.log('TIMESLOT ACTION CALLED')
        getTimeSlotData()
            .then((res)=>{
                const data = res.map((e,i) => ({...e, sn:i+1}))
                console.log('DATA in ACTION', data)
                if(data){
                    dispatch({type:'MANAGE_TIMESLOT_DATA',payload:data})
                    console.log('data', data)
                }
           })
        .catch((err) => console.log('error TIMESLOT: ', err))
        
    }
}

export function sortArrayOfObjectsByName(arr,sortOn) {
    // Use the JavaScript Array sort() method to sort the array
    arr.sort(function(a, b) {
      // Convert the name property of each object to lowercase for case-insensitive sorting
      var nameA = a[sortOn].toLowerCase();
      var nameB = b[sortOn].toLowerCase();
  
      // Use the localeCompare() method to compare the names and determine the sorting order
      // Returns -1 if nameA should be sorted before nameB
      // Returns 1 if nameA should be sorted after nameB
      // Returns 0 if the names are equal and their order doesn't matter
      return nameA.localeCompare(nameB);
    });
  
    // Return the sorted array of objects
    return arr;
  }

  
export const indexingOfProducts=(products)=>{

    const data = JSON.parse(JSON.stringify(products))
    const sortedData = sortArrayOfObjectsByName(data,'genericName')

    let obj={}

    sortedData.map((product,index)=>{

        const initial = product.genericName[0]

        if(obj.hasOwnProperty(initial.toLowerCase())){
            
            obj[initial.toLowerCase()] = {...obj[initial.toLowerCase()],end:index}
        
        }else{
            obj[initial.toLowerCase()] = {start:index,end:index}
        }

    })


    return {obj,sortedData}
}

export default {bookingHandler, bookingHandlerForShop,getProductsForShop,productsHandler, timeslotHandler}
