// ** Auth Endpoints
export default {
  loginEndpoint: '/auth/login',
  logoutEndpoint: '/jwt/logout',
  
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'x-access-token',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access_token'
}
