// ** Navigation imports
import apps from './apps'
import {store} from "../../redux/store"
import {
    Mail,
    Home,
    Map,
    Book,
    BarChart2,
    Circle,
    Sliders,
    Plus,
    PieChart,
    Disc,
    Tag
} from "react-feather"

import ManageRoutes from "../vertical/apps"
import themeConfig from '../../configs/themeConfig'
import { useSelector } from 'react-redux'


const Routes = [
    ...ManageRoutes
  ]
const accessState = store.getState().accessPage.access


const iconList={

    "Views":<Home/>,
    "Home":<Home/>,
    "GM Dashboard":<Sliders />,
    "Manage":<Sliders />,
    "Intel":<BarChart2 />,
    "Reports":<Book />,
    "Report Section":<Book />,
    "Executions":<Sliders />,
    "Invoices":<Tag/>


}




export const getNavigation = async (role,applybrand)=>{

    const navigation = localStorage.getItem('navigation')
    const accountType = localStorage.getItem('accountType')
    
    
    //let user =localStorage.getItem('userData')
    let user =role;
    
    let admin = [0,2,4,6,10,12,13];
    let artist = [0,7,1,2,5];
    let tiktoker = [0,7,6];
    let brand = [0,7,11,2,8,9];
    let nav=[];
//     if(!user){
//         [0].map(n=>{
//             let row=apps.find(x=>x.id==n);
//             if(row){
//                 nav.push(row);
//             }
//         })
// return nav;

//     }

if(applybrand && applybrand=='yes'){
    //artist.push(10);
    tiktoker.push(10)
}
   
    if(role=='artist' ||role=='musician'){
        artist.map(n=>{
            let row=apps.find(x=>x.id==n);
            if(row){
                nav.push(row);
            }
        })
    }
    else if(role=='tiktoker'){
        tiktoker.map(n=>{
            let row=apps.find(x=>x.id==n);
            if(row){
                nav.push(row);
            }
        })
    }
    else if(role=='brand'){
        brand.map(n=>{
            let row=apps.find(x=>x.id==n);
            if(row){
                nav.push(row);
            }
        })   
    }
    else if(role=='admin'){
        admin.map(n=>{
            let row=apps.find(x=>x.id==n);
            if(row){
                nav.push(row);
            }
        })   
    }else{
        [0].map(n=>{
            let row=apps.find(x=>x.id==n);
            if(row){
                nav.push(row);
            }
        })
    }
    // if(navigation!='undefined' && navigation!=null && navigation.length!=0){
        
    //     const filteredNavigation = await filterNavigation(JSON.parse(navigation))
    //     return filteredNavigation

    // }
    return nav 
}
export default [...apps]
