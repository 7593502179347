
const initialState={

    selectedSound:null,
    selectedBrand:null
}

const sound = (state = initialState, action) => {
    
    switch(action.type){
        
        case "SET_SELECTED_SOUND":
            return{
                ...state,
                selectedSound:action.payload
            }
        case "SET_SELECTED_BRAND":
            return{
                ...state,
                selectedBrand:action.payload
            }
        default:
            return state
    }

  }
  
  export default sound
  