// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import accessPage from './access'
import ManageReducer from '../views/Manage/Redux/Slice'

import dashboardReducer from './reducers/dashboardReducer'
import soundReducer from './Sound'



const rootReducer = {
  auth,
  navbar,
  layout,
  accessPage,
  ManageReducer,  
soundReducer,

  dashboardReducer
}

export default rootReducer
