import {axios,Headers} from "../../../@core/auth/jwt/jwtService"
import themeConfig from "../../../configs/themeConfig"
import {
        httpManageAdminData,
        httpManageAdminEditData,
        toUploadFile,
        httpDataForDashboard, 
        httppostSound, 
        httpGetArtistSound, 
        httpPostCategory, 
        httpPostUpdateCategory, 
        httpGetCategory, 
        httpGetAllUsers, httpGetUserById,
        httpPostDeleteUser,
        httpGetAllSound,
        httpPostUseSound,
        httpPostApproveByArtist,
        httpPostApproveByAdmin,
         httpGetApprovalById,
         httpGetApprovals,
         httpPostCreateBrandRequest,
         httpGetBrandRequests,
         httpGetAllBrandRequests, 
         httpPostUser,
         httpGetArtistDashbord,
         httpPostVerifyFollowers,
         httpPostApplyBrand,
         httpGetArtistSoundWithLocking,
         httpGetBrandRequestsWithLocking,
         httpGetAllTransactions,
         httpPostWithdrawtoBank,
         httpPostFundUserWallet,
         httpPostApproveAllByArtist,
         httpPostAddClick,
         httpGetUserByEmail,
         httpPostEmail,
         httpGetUserByResetCode,
         httpPostResetPassword,
         httpPostAccountApprovalStatus,
         httpPostCheckClickAndPendingReq,
         httpGetUsersWithPendingApprovals,
         httpGetNotifications


    } from './ManageConstants'



// POST
const postManageHOF = (manageApi, data,id) => {

    let URL = localStorage.getItem('baseURL')
    if(id){ return axios.post(`${URL}${manageApi}?id=${id}`, data,Headers)}
    
    return axios.post(`${URL}${manageApi}`, data,Headers)
}

const postPaymentHOF = (manageApi, data,id) => {
    return axios.post(`https://walletapi.paytok.com.ng${manageApi}`, data,Headers)
}

// GET
// Get Manage HOF
const getManageHOF = (manageApi) => {

    let URL = localStorage.getItem('baseURL')
    return axios.get(`${URL}${manageApi}`,Headers) 
}


//  Admin POST
const postManageEditAdminData = (data, id) => postManageHOF(httpManageAdminEditData, data, id)

const uploadFile = (data, id) => postManageHOF(toUploadFile, data, id)


//  admin GET
const getManageAdminData = () => getManageHOF(httpManageAdminData)
//  agent GET


const postNewSound =(data, id)=>postManageHOF(httppostSound, data)
const getUserWallet =(data, id)=>postManageHOF(`/auth/get-user-wallet?user_id=${data.uid}`, data)
const postCreateBrandRequest =(data, id)=>postManageHOF(httpPostCreateBrandRequest, data)
const getBrandRequest =(data)=>getManageHOF(httpGetBrandRequests+`?brand_id=${data.brand_id}&type=${data.type}`)
const getBrandRequestWithLocking =(page)=>getManageHOF(httpGetBrandRequestsWithLocking+`?page=${page}`)
const getAllBrandRequest =()=>getManageHOF(httpGetAllBrandRequests)

// Data for Dashboard
const getDataForDashboard=()=>getManageHOF(httpDataForDashboard)


//PAYTOK
const getArtistSound = (artist_id) => getManageHOF(httpGetArtistSound+`?artist_id=${artist_id}`)
const getArtistSoundWithLocking = (page) => getManageHOF(httpGetArtistSoundWithLocking+`?page=${page}`)
const getAllSound = (artist_id) => getManageHOF(httpGetAllSound)
const postCreateCategory = (data) => postManageHOF(httpPostCategory,data)
const postUpdateCategory = (data) => postManageHOF(httpPostUpdateCategory,data)
const getAllCategories = (data) => getManageHOF(httpGetCategory)
const getAllUsers = (data) => getManageHOF(httpGetAllUsers)
const getUserById = (data) => getManageHOF(httpGetUserById+`?userid=${data.userid}`)
const postDeleteUser = (data) => postManageHOF(httpPostDeleteUser,{uid:data})
const postUseSound = (data) => postManageHOF(httpPostUseSound,data)
const postApprovalByArtist = (data) => postManageHOF(httpPostApproveByArtist,data)
const postApprovalAllByArtist = (data) => postManageHOF(httpPostApproveAllByArtist,data)
const postApprovalByAdmin = (data) => postManageHOF(httpPostApproveByAdmin,data)
const getApprovalById = (data,role) => getManageHOF(httpGetApprovalById+`?artist_id=${data}&role=${role}`)
const getApprovals = (data) => getManageHOF(httpGetApprovals+`?user_id=${data}`)
const postUser = (data) => postManageHOF(httpPostUser,data)
const getArtistDashboard = (data) => getManageHOF(httpGetArtistDashbord+`?user_id=${data.user_id}`)
const postVerifyFollowers = (data) => postManageHOF(httpPostVerifyFollowers,data)
const postApplyBrand = (data) => postManageHOF(httpPostApplyBrand,data)

//wallet apis
const postCreateWallet = (data) => postPaymentHOF('/create',data)
const postAddBankAccountInit = (data) => postManageHOF('/auth/bank-add-init',data)
const postAddBankAccount = (data) => postManageHOF('/auth/bank-add',data)
const postWithdrawtoBank = (data) => postManageHOF(httpPostWithdrawtoBank,data)
const getBankList = (data) => getManageHOF('/auth/get-banks-list?user_id='+data.user_id)
const getAllTransactions = (data) => getManageHOF(httpGetAllTransactions)
const postFundUserWallet = (data) => postManageHOF(httpPostFundUserWallet,data)
const postAddClicks = (data) => postManageHOF(httpPostAddClick,data)
const getUserByEmail = (data) => getManageHOF(httpGetUserByEmail+`?email=${data.email}`)
const postResetEmail = (data) => postManageHOF(httpPostEmail,data)
const postResetPassword = (data) => postManageHOF(httpPostResetPassword,data)
const getUserByResetCode = (data) => getManageHOF(httpGetUserByResetCode+`?code=${data.code}`)
const updateAccountApprovalStatus = (data) => postManageHOF(httpPostAccountApprovalStatus,data)
const checkClicksandPendingRequests = (data) => postManageHOF(httpPostCheckClickAndPendingReq,data)
const getUsersWithPendingRequests = (data) => getManageHOF(httpGetUsersWithPendingApprovals)
const getUsersNotifications = (data) => getManageHOF(httpGetNotifications+`?user_id=${data}`)



export default {
   
    
    getManageAdminData,
    postManageEditAdminData,
    uploadFile,
    getUsersNotifications,
    
    getDataForDashboard,
    postNewSound,
    getArtistSound,
    postCreateCategory,
    postUpdateCategory,
    getAllCategories,
    getAllUsers,
    getUserById,
    postDeleteUser,
    getAllSound,
    postUseSound,
    postApprovalByAdmin,
    postApprovalByArtist,
    getApprovalById,
    getApprovals,
    postCreateBrandRequest,
    getBrandRequest,
    getAllBrandRequest,
    postUser,
    getUserWallet,
    getArtistDashboard,
    postVerifyFollowers,
    postApplyBrand,
    postCreateWallet,
    postAddBankAccount,
    postAddBankAccountInit,
    getBankList,
    getArtistSoundWithLocking,
    getBrandRequestWithLocking,
    getAllTransactions,
    postWithdrawtoBank,
    postFundUserWallet,
    postApprovalAllByArtist,
    postAddClicks,
    getUserByEmail,
    postResetEmail,
    getUserByResetCode,
    postResetPassword,
    updateAccountApprovalStatus,
    checkClicksandPendingRequests,
    getUsersWithPendingRequests

}
